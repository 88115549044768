<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    en: {},
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      plan: null,
      loading: false,
    };
  },
  methods: {
    getPlan() {
      api.get("tv/plans/" + this.$route.params.id).then((response) => {
        if (response.data.status == "success") {
          this.plan = response.data.plan;
        }
      });
    },
    contractPlan() {
      this.loading = true;

      api
        .post("tv/subscribers", {
          plan: this.plan.id,
        })
        .then((response) => {
          if (response.data.status == "success") {
            if (response.data.order &&
                response.data.order.id) {
              this.$router.push("/store/order/" + response.data.order.id);
            } else {
              this.$router.push("/tv/subscriber");
            }
          } else {
            this.$noty.error(response.data.message);
          }

          this.loading = false;
        });
    },
  },
  mounted() {
    this.getPlan();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Planos") }}</h4>
        </div>
      </div>
    </div>

    <div v-if="!plan" class="text-center">
      <b-spinner small variant="default"></b-spinner>
    </div>
    <div v-else-if="plan && plan.name">
      <div class="card">
        <div class="card-header bg-default rounded-top py-3">
          <p class="m-0 text-white">Plano</p>
          <h4 class="plan-name m-0 text-white">{{ plan.name }}</h4>
        </div>
        <div class="card-body">
          <h4 class="plan-price">{{ plan.value | currency }}/mês</h4>

          <div v-for="(description, index) in plan.features" :key="index">
            <i class="bx bx-plus"></i> {{ description }}
          </div>

          <button
            class="mt-3 btn btn-default px-4"
            v-on:click="contractPlan()"
            :disabled="loading"
          >
            Contratar
            <b-spinner
              v-if="loading"
              small
              class="ml-2 align-middle"
              variant="white"
              role="status"
            ></b-spinner>
          </button>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.plan-price {
  border-bottom: 5px solid #555;
  padding-bottom: 3px;
}
</style>
